<template>
  <div class="Login-box">
    <header>
      <h1>Welcome to Reachmybox</h1>
    </header>
    <section>
      <div class="social-login">
        <button @click="googleSignIn">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            alt="google"
            width="20"
          ><span>Google</span>
        </button>
      </div>
      <form
        @submit.prevent="submitForm"
        class="login-form"
      >
        <div class="input-group">
          <label for="Email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Your Email"
            v-model.trim="email"
          >
        </div>
        <div class="input-group">
          <label for="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Password"
            v-model.trim="password"
          >
        </div>
        <div class="input-group">
          <button>Login</button>
        </div>
        <p
          v-if="!isFormValid">
          Please enter a valid email or password.
        </p>
        <a
          href="#"
          title="Forgot Password"
          @click="forgotPassword(email)"
        >Forgot Password</a>
      </form>
    </section>
    <footer>
      <div class="footerText">
        New here ?
      </div>
      <router-link to="/register">
        Sign Up
      </router-link>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useAuth } from '@/composable/auth.js';

export default {
  setup() {
    const router = useRouter();
    let isFormValid = true;
    const email = ref('');
    const password = ref('');
    const { signIn, forgotPassword } = useAuth();

    async function submitForm() {
      isFormValid = true;

      if (await signIn('Email', email.value, password.value)) {
        router.push('/home');
      }
    }

    async function googleSignIn() {
      if (await signIn('Google')) {
        router.push('/home');
      }
    }

    return {
      email,
      password,
      signIn,
      forgotPassword,
      isFormValid,
      submitForm,
      googleSignIn
    };
  },
};
</script>
